<template>
    <div class="to-top">
        <b-alert :show="alertDanger.dismissCountDown" fade id="alert-danger"
                @dismiss-count-down="countDownChangedDanger" class="alert-danger multi-line pre-formatted">
                {{ alertDanger.notification }}
            <div class="x dismiss-x" @click="() => {this.alertDanger.dismissCountDown = 0}"></div>
        </b-alert>
    <div class="sidenav tr-container tr-glasses tr-glasses-login--intro tr-section-100vh">
        <a href="/" class="logo">
          <img alt="logo trekio" src="../../assets/svg/trekio-logo.svg">
        </a>
        <div class="login-main-text">
            <h1>{{ $t("public.loginPage.welcomeBack") }}</h1>
        </div>
    </div>
    <div class="main">
        <div class="col-12">
            <div class="login-form">
                <form class="d-flex flex-column" @submit.prevent="login" style="gap: 30px" novalidate>
                    <a class="back-button" @click="$router.go(-1)">
                        <img src="../../assets/svg/icon-arrow-left.svg" alt="">
                        {{ $t("general.back") }}
                    </a>
                  <span style="font-size: 25px">{{ $t("general.login") }}</span>
                  <div>
                    {{ $t("public.loginPage.loginToContinueToAdmin") }}
                    <span v-if="errorMessage" class="alert-danger px-2 py-1 mt-2 d-inline-block">{{ errorMessage }}</span>
                  </div>
                    <TrekioInput id="userName" v-model="username" :label="$t('inputLabels.login')" maxLength="100"
                        dynamicLabel :error="error.userName" placeholder=" " required white-bg
                    />
                    <TrekioInput type="password" id="password" v-model="password" :label="$t('inputLabels.password')" maxLength="100"
                        dynamicLabel :error="error.password" placeholder=" " :required="true" white-bg
                    />
                    <TrekioButton primary :isLoading="loading != ''" :loadingText="loading" type="submit" @click.prevent="login">{{ $t('buttons.login') }}</TrekioButton>
                </form>
            </div>
        </div>
        <div class="register-link-container col-12">
            <div>{{ $t("public.loginPage.noAccountYetAuthor") }}</div>
            <router-link to="/registerAuthor" style="color: #30E781">{{ $t("public.loginPage.registerAuthor") }}</router-link>
        </div>
    </div>
      <label class="trekio-version mb-0">Trekio • Verze 2.1</label>
    </div>
</template>

<script>
    import { AUTH_REQUEST } from "@/store/actions/auth";

    export default {
        name: "LoginPage",

        data: function() {
            return {
                username: null,
                password: null,
                loading: '',
                error: {
                    userName: '',
                    password: ''
                },
                errorMessage: null,
                showPassword: false,
                alertDanger: {
                    dismissSecs: 999,
                    dismissCountDown: 0,
                    showDismissibleAlert: false,
                    notification: ''
                },
            }
        },
        methods: {
            async recaptcha() {
                const token = await this.$recaptchaToken('LOGIN')
                return token
            },
            changePasswordVisibility() {
                this.showPassword = !this.showPassword 
            },
            validateInputs() {
                let errorCount = 0
                const inputs = document.querySelectorAll("input")
                this.error.userName = this.error.password = ''

                inputs.forEach(input => {
                    if (input.required && !input.value) {
                        this.error[input.id] = this.$t("inputErrors.required")
                        errorCount += 1
                    }
                })
                return errorCount > 0 ? false : true
            },

            login: async function () {
                if (!this.validateInputs()) return
                this.loading = this.$t("loading.login")
                const recaptchaToken = await this.recaptcha()

                const { username, password } = this;
                console.log("Starting authentication with username "+username);
                const currentPreferredLanguage = this.$store.state.preferredLanguage
                this.$store
                    .dispatch(AUTH_REQUEST, {username, password, recaptchaToken})
                    .then(async (resp) => {
                        if (this.$route.query.returnUrl) await this.$router.push(this.$route.query.returnUrl)
                        else await this.$router.push(this.$store.state.currentUserRole === 'AUTHOR' ? 'admin/travelTips' : '/admin')
                        if (resp.data.preferredLanguage != currentPreferredLanguage) {
                            location.reload()
                        }
                    })
                    .catch(err => {
                        this.error.userName = this.error.password = ' '
                        this.showAlertDanger(this.$t('alerts.loginError'));
                    })
            },

            goBack() {
                this.$router.push({
                    name: 'HomePage',
                })
            },
            showAlertDanger: function(notification) {
                this.loading = ''
                this.alertDanger.dismissCountDown = this.alertDanger.dismissSecs;
                this.alertDanger.notification = notification;
            },
            countDownChangedDanger: function(dismissCountDown) {
                this.alertDanger.dismissCountDown = dismissCountDown;
            },
        }
    }
</script>

<style scoped lang="scss">
    @import '@/scss/variables';

    .back-button {
        display: flex;
        height: 40px;
        align-items: center;
        color: $tr-black;
        margin-top: -30px;

        &:hover {
            text-decoration: none;
            color: $tr-black;
        }

        img {
            margin-right: 1rem;
        }
    }

    .register-link-container {
        max-width: 360px;
        margin-top: 30px;
        text-align: center;
    }

    .to-top {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $tr-white-alt;
        overflow-y: scroll;
    }

    .sidenav {
        display: none;
        height: 100%;
        overflow-x: hidden;
        padding-top: 20px;
    }

    .main {
        padding: 0 10px;
        margin-top: 40px;
    }
    .trekio-version {
      color: darkgrey;
      position: fixed;
      left: 20px;
      bottom: 20px;
    }

    @media screen and (max-height: 450px) {
        .sidenav {padding-top: 15px;}
    }

    @media screen and (max-width: 450px) {
        .login-form{
            margin-top: 10%;
        }
    }

    @media screen and (min-width: 768px){
        .main{
            margin-left: 60%;
            margin-top: 110px;
        }

        .back-button {
            display: none;
        }

        .sidenav{
            display: block;
            width: 60%;
            position: fixed;
            z-index: 1;
            top: 0;
            left: 0;
        }

        .trekio-version {
            bottom: 60px;
            left: 60px;
        }
    }

    .login-main-text{
        padding: 60px;
        color: #6c757d;
    }

    .login-main-text h1{
        font-weight: 500;
        font-size: 50px;
    }
    .login-form {
      text-align: left;
    }

    
    .logo {
      position: absolute;
      left: 60px;
    }

</style>
